<template>
    <div v-loading="editLoading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >运营和营销</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/promotion' }">团购</el-breadcrumb-item>
                <el-breadcrumb-item>编辑活动</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini" >


                <el-form-item label="名称：" label-position="left" prop="name" >
                    <el-input  style="width: 200px" v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="封面图片：" label-position="left" prop="pic" >
                    <edit-cover @success="uploadSuccess" :pic=" imageUrl + form.pic" :uploadPath="uploadPath"></edit-cover>
                </el-form-item>

<!--                <el-form-item label="分享海报：" label-position="left" prop="SharePic" >-->
<!--                    <edit-cover @success="uploadSuccessSharePic" :pic=" imageUrl + form.sharePic" :uploadPath="uploadPath">-->
<!--                        <span slot="prompt">275*380像素或16:9，支持PNG、JPG、GIF格式，小于5M</span>-->
<!--                    </edit-cover>-->
<!--                </el-form-item>-->

                <el-form-item label="选择课程：" label-position="left" prop="name" >
                    <edit-product :select-product="selectProducts" @onSelect="onSelect1" @resetSelect="resetSelect"></edit-product>
                </el-form-item>

                <el-form-item label="详情：" label-position="left" prop="content" >
                    <editor @onChange="onChange" :content="form.content"></editor>
                </el-form-item>

                <el-form-item label="活动时间：" label-position="left" class="input"  prop="startTime">
                    <el-date-picker
                            size="small"
                            v-model="value2"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            @change="changeDateTime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="价格：" label-position="left" class="input" prop="amount">
                    <el-input style="width: 200px"  v-model="form.amount"></el-input> 元
                </el-form-item>
                <el-form-item label="成团人数：" label-position="left"  prop="amount">
                    <el-input style="width: 200px"  v-model="form.number" size="mini"></el-input>
                    <div class="txt-info">成功组团人数</div>
                </el-form-item>

                <el-form-item label="优惠配置：" label-position="left" >
                    <el-checkbox :disabled="form.number>0 ? null : 'disabled'" v-model="form.configDiscount" :true-label="1" :false-label="0" size="mini">开启配置团购优惠</el-checkbox>
                    <div class="txt-info">开启后可以配置邀请好友减额优惠</div>

                    <div v-if="form.configDiscount === 1">
                        <el-table
                                style="border: 1px #F2F6FC solid;width: 303px"
                                empty-text="请添加配置"
                                :header-cell-style="{
                                    'background-color': '#fafafa',
                                    'text-align': 'center',
                                    'color': 'rgb(103, 194, 58)',
                                }"
                                :row-style="{
                                    'text-align': 'center',
                                }"
                                ref="multipleTable"
                                :data="form.configs">
                            <el-table-column
                                    label="邀请人数"
                                    width="150">
                                <template  slot-scope="scope" >
                                    <el-input v-model="scope.row.number" disabled></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="优惠金额"
                                    width="150">
                                <template  slot-scope="scope" >
                                    <el-input v-model="scope.row.discount_amount" ></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="margin-top: 20px">
                            <el-button type="primary" @click="addConfigs">添加配置</el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleDelete">重新配置</el-button>
                        </div>
                    </div>

                </el-form-item>

                <el-form-item label="模拟拼团：" label-position="left" >
                    <el-checkbox v-model="form.isSimulate" :true-label="1" :false-label="0" size="mini">开启模拟拼团</el-checkbox>
                    <div class="txt-info">开启后活动结束时会模拟系统用户参与成功拼团</div>
                </el-form-item>

                <el-form-item label="失败退款：" label-position="left" >
                    <el-checkbox v-model="form.failRefund" :true-label="1" :false-label="0" size="mini">开启失败退款</el-checkbox>
                    <div class="txt-info">开启后活动结束时拼团失败后会原路退回用户支付金额</div>
                </el-form-item>

                <el-form-item label="有效时间：" label-position="left"  prop="amount">
                    <el-input style="width: 200px"  v-model="form.inDate" size="mini"></el-input> 天
                    <div class="txt-info">用户开团之后，至结束的有效时间</div>
                </el-form-item>

                <el-form-item label="显示列表：" label-position="left" >
                    <el-checkbox v-model="form.showList" :true-label="1" :false-label="0" size="mini">开启拼团的列表</el-checkbox>
                    <div class="txt-info">开启后在团购页面将显示正在拼团的列表，方便用户参加拼团</div>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus" :active-value="1" :inactive-value="0">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/groupBuying" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import Editor from "../../../components/editor";
    import EditCover from "../../../components/editCover";
    import EditProduct from "../../../components/editProduct";
    export default {
        name: "template-add",
        components: {EditCover, EditProduct, Editor},
        data() {
            return {
                selectProducts:[],
                imageUrl:config.imageUrl,
                editLoading:false,
                statusTxt:'上架',
                loading:false,
                uploadPath:'promotion',
                form: {
                    id:0,
                    name:'',
                    type: 1,
                    status:1,
                    pic:'',
                    sharePic:'',
                    amount:0,
                    content:'',
                    number:0,
                    startTime:'',
                    endTime:'',
                    courseIds:[],
                    configs:[],
                    configDiscount:0,
                    isSimulate:0,
                    failRefund:0,
                    showList:0,
                    inDate:1,
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < (Date.now() - 3600 * 1000 * 24);
                    },
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                options: [
                    {
                        value: 1,
                        label: '组合购'
                    },
                ],
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                    ],
                    pic: [
                        { type: 'string', required: true, message: '请选择图片封面', trigger: 'change' }
                    ],
                    // sharePic: [
                    //     { type: 'string', required: true, message: '请选择分享海报图', trigger: 'change' }
                    // ],
                    content: [
                        { type: 'string', required: true, message: '请输入课程内容', trigger: 'blur' }
                    ],
                    amount: [
                        {  required: true, message: '请输入价格', trigger: 'blur' },
                    ],
                },

            }
        },
        methods: {
            ...mapActions('marketing',['addGroupBuying','getGroupBuyingDetail']),
            uploadSuccess(path){
                this.form.pic = path
            },
            addConfigs(){
                if(this.form.configs.length >= this.form.number)
                {
                    this.$message.error('邀请人数不能大于成团人数')
                    return
                }
                this.form.configs.push({
                    number:this.form.configs.length+1,
                    discount_amount:0
                })

            },
            handleDelete(){
                this.form.configs = []
            },
            onSelect1(product){
                console.log(product)
                this.form.courseIds = product[0].ids
            },
            resetSelect(){
                this.form.courseIds = []
            },
            uploadSuccessSharePic(path){
                this.form.sharePic = path
            },
            changeDateTime(value){
                console.log(value !== null)
                if(value !== null){
                    this.form.startTime = value[0]
                    this.form.endTime = value[1]
                }else{
                    this.form.startTime = ''
                    this.form.endTime = ''
                }

            },
            onChange(content){
                this.form.content = content
            },
            onSelect(product){
                console.log(product)
                this.form.courseIds = product[0].ids
            },
            async onSubmit() {
                let _this = this

                console.log(this.form)

                if(!this.validateForm('form'))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }
                this.loading = true

                let res = await this.addGroupBuying(this.form)

                if(res.ret == 0)
                {
                    this.$message.success('新建活动成功！')
                    this.$router.push('/groupBuying')
                }

                this.loading = false
            },
            setStatus(status){
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            selectType(val){
                this.form.type = val
            },
            validateForm(formName){
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },
        },
        async mounted() {
            this.editLoading = true
            this.form.id = this.$route.params.id
            let detail = await this.getGroupBuyingDetail(this.form.id)
            this.form.name = detail.data.name
            this.form.amount = detail.data.amount
            this.form.content = detail.data.content
            this.form.pic = detail.data.pic
            this.form.sharePic = detail.data.share_pic
            this.form.configs = detail.data.configs
            this.form.configDiscount = detail.data.config_discount
            this.form.number = detail.data.number
            this.form.startTime = detail.data.start_at
            this.form.endTime = detail.data.end_at
            this.form.isSimulate = detail.data.is_simulate
            this.form.failRefund = detail.data.fail_refund
            this.form.showList = detail.data.show_list
            this.form.inDate = detail.data.indate

            if(this.form.startTime !== null && this.form.endTime !== null){
                this.value2 = [this.form.startTime,this.form.endTime]
            }
            this.form.courseIds = detail.data.courseIds
            this.form.status = detail.data.status
            this.selectProducts = detail.data.courses
            this.editLoading = false
        }
    }
</script>

<style>
.txt-info{
    color: #909399;
    font-size: 12px;
}
</style>
